<template>
  <!--begin::Statistics Widget 1-->
  <div
    :class="widgetClasses"
    :style="`background-image: url('/media/svg/shapes/${background}')`"
    class="card bgi-no-repeat"
    style="background-position: right top; background-size: 30% auto"
  >
    <!--begin::Body-->
    <div class="">
      <div
        class="row card-title fw-bolder text-gray-800 text-hover-primary fs-6 m-4"
      >
        {{ title }}
      </div>
      <hr />
      <div class="m-5 px-4">
        <div class="row py-2">
          <div class="col-9">
            <p class="text-gray-700 fw-bold fs-7 m-0">
              <span v-html="description1"></span>
            </p>
          </div>
          <div class="col-3 text-end">
            <span class="fw-bolder fs-5">
              <!--      <a :href="link1"> -->
              <a href="#" @click="report()">
                <i class="bi bi-download text-hover-primary text-dark fw-bold">
                </i> </a
            ></span>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-9">
            <p class="text-gray-700 fw-bold fs-7 m-0">
              <span v-html="description2"></span>
            </p>
          </div>
          <div class="col-3 text-end">
            <span class="fw-bolder fs-5"
              ><a href="#" @click="report()">
                <i class="bi bi-download text-hover-primary text-dark fw-bold">
                </i> </a
            ></span>
          </div>
        </div>
        <div class="row py-2" v-if="description3">
          <div class="col-9">
            <p class="text-gray-700 fw-bold fs-7 m-0">
              <span v-html="description3"></span>
            </p>
          </div>
          <div class="col-3 text-end">
            <span class="fw-bolder fs-5"
              ><a href="#" @click="report()">
                <i class="bi bi-download text-hover-primary text-dark fw-bold">
                </i> </a
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    background: String,
    title: String,
    description1: String,
    description2: String,
    description3: String,
    link1: String,
    link2: String,
    link3: String,
  },
  components: {},
  setup() {
    const report = () => {
      Swal.fire({
        html: "Il report non è al momento disponibile.",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    return { report };
  },
});
</script>
