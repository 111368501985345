
import { defineComponent, onMounted } from "vue";
import StatisticsWidget02 from "../../components/widgets/statsistics/Widget02.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "report",
  components: { StatisticsWidget02 },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Report Economici", []);
    });
  },
});
